.codebox {
  position: relative;
}

.copyButton {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 6px;
  margin: 0;
  font-size: 16px;
  color: white;
}
