$content-width: 1100px;

.header {
  background-color: #fff;

  .layout {
    width: $content-width;
    margin: 0 auto;

    .layoutMenu {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .menuItem {
        min-width: 100px;
        text-align: center;
        font-size: 1rem;
        padding: 0px 20px;
        margin: 0px 5px;
      }

      .language {
        float: right;
      }
    }
  }
}

.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}

.logo {
  width: 180px;
  height: 31px;
  margin: 16px 24px 16px 10px;
  float: left;
  background-image: url("leetcode.png");
  background-size: auto 60px;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;
}

.questions-content {
  padding: 20px 0px;
  width: $content-width;
  margin: 0 auto;
}

.content {
  padding: 20px 0px;
}

.content-description {
  p {
    margin-bottom: 1rem;
  }
  img {
    max-width: 100%;
    height: auto!important;
  }
  pre {
    background-color: rgba(0,10,32,.03);
    border-radius: 0.5rem;
    color: rgba(38,38,38,.75);
    font-family: Menlo,sans-serif;
    font-size: .875rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    white-space: pre-wrap;
  }
  code {
    background-color: rgba(0,10,32,.03);
    border-color: rgba(0,0,0,.05);
    border-radius: 5px;
    border-width: 1px;
    color: rgba(38,38,38,.75);
    font-family: Menlo,sans-serif;
    font-size: .75rem;
    line-height: 1rem;
    padding: 0.125rem;
    white-space: pre-wrap;
  }
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
}

.questionItem,
.questionHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex: auto;

  .description {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: right;

    .difficult {
      margin-right: 10px;

      .easy {
        color: #009975;
      }

      .hard {
        color: #ec4c47;
      }

      .medium {
        color: #ed7336;
      }
    }

    .ace,
    .difficult,
    .paid_only,
    .solution_icon {
      text-align: center;
      width: 80px;
    }
  }
}

.pagination {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
